<template>
  <div>
    test
  </div>
</template>

<script>


export default {
  name: "Details",
  mounted() {
    console.log('le detail ===>,', this.params.data)
  },
}
</script>

<style scoped>

</style>
